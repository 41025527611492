<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Statistika"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-col cols="12" class="text-center" v-if="!loaded">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-button class="mb-3" @click="getExport()" v-if="!statisticsType" variant="success">Eksportēt</b-button>

          <b-button v-b-toggle.sidebar-right class="mb-3 float-right" variant="success">Filtrēt</b-button>

          <b-table striped hover responsive="true" small v-b-hover fixed
                   ref="table"
                   :items="items"
                   :fields="fields"
                   v-if="loaded && statisticsType == 0">

            <template #cell(hourCount)="row">
              {{ row.item.hourCount ? row.item.hourCount : 0 }}
            </template>

            <template #cell(actions)="row">
              <b-button size="sm" variant="warning" @click="$router.push({ name: 'DocumentList', params: {name: row.item.name+' '+row.item.surname} })" class="mr-1">
                Skatīt
              </b-button>
            </template>

          </b-table>

          <b-table striped hover responsive="true" small v-b-hover fixed
                   ref="table"
                   :items="items"
                   :fields="userFields"
                   v-if="loaded && statisticsType == 1">

            <template #cell(hourCount)="row">
              {{ row.item.hourCount ? row.item.hourCount : 0 }}
            </template>

            <template #cell(actions)="row">
              <b-button size="sm" variant="warning" @click="$router.push({ name: 'UserEdit', params: {id: row.item.id} })" class="mr-1">
                Skatīt
              </b-button>
            </template>

          </b-table>

          <template v-if="!items">
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Dati tiek lejupielādēti...</strong>
            </div>
          </template>

        </b-card>
      </b-col>

      <b-sidebar id="sidebar-right" title="Statistikas filtrēšana" right shadow>
        <template #default="{ hide }">
          <div class="p-3">
            <b-row>
              <b-col cols="12 mt-2">
                <h6>Statistikas veids: {{ statisticsType ? 'Darbinieku' : 'Dokumentu' }}</h6>
                <b-form-checkbox v-model="statisticsType" name="check-button" switch>
                </b-form-checkbox>
              </b-col>

              <b-col cols="12 mt-4" v-if="statisticsType">
                <h6>Vecums:</h6>
                <label for="min-age">No: {{ ageSlider[0] }}</label>
                <b-form-input id="min-age" type="range" min="0" max="100" v-model="ageSlider[0]"></b-form-input>

                <label for="max-age">Līdz: {{ ageSlider[1] }}</label>
                <b-form-input id="max-age" type="range" min="0" max="100" v-model="ageSlider[1]"></b-form-input>
              </b-col>

              <b-col cols="12 mt-2 mb-3" v-if="statisticsType">
                <h6>Dzimums:</h6>
                <b-form-checkbox value="1" v-model="gender[0]">Vīrietis</b-form-checkbox>

                <b-form-checkbox value="0" v-model="gender[1]">Sieviete</b-form-checkbox>
              </b-col>

              <b-col cols="12 mt-4" v-else>
                <h6>Status:</h6>
                <b-form-checkbox value="0" v-model="status[0]">Pedagoģiskais darbinieks</b-form-checkbox>

                <b-form-checkbox value="1" v-model="status[1]">Tehniskais darbinieks</b-form-checkbox>

                <b-form-checkbox value="2" v-model="status[2]">Tehniskais/pedagoģiskais darbinieks</b-form-checkbox>
              </b-col>
            </b-row>

            <b-button variant="primary" block @click="getData(); hide">Filtrēt</b-button>
          </div>
        </template>
      </b-sidebar>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "List",
  data() {
    return {
      documents: null,
      created: null,
      items: null,
      fields: [
        { key: 'name', label: 'Vārds', sortable: true },
        { key: 'surname', label: 'Uzvārds', sortable: true },
        { key: 'personal_code', label: 'Pers. kods', sortable: true },
        { key: 'upbringing', label: 'Audzināšanas stundas', sortable: true },
        { key: 'children_rights', label: 'Bērnu tiesības', sortable: true },
        { key: 'additional_courses', label: 'Kursi', sortable: true },
        { key: 'totalHourCount', label: 'Kopā', sortable: true },
        { key: 'actions', label: 'Darbības' },
      ],
      userFields: [
        { key: 'name', label: 'Vārds', sortable: true },
        { key: 'surname', label: 'Uzvārds', sortable: true },
        { key: 'personal_code', label: 'Pers. kods', sortable: true },
        { key: 'birthdate', label: 'Dzimšanas datums', sortable: true },
        { key: 'actions', label: 'Darbības' },

      ],
      loaded: true,
      statisticsType: 0,
      ageSlider: [0, 100],
      gender: [false, false],
      status: [0, false, false],
    }
  },
  mounted() {
    this.getDocumentStatistics();
  },
  computed: {
    ...mapGetters({
      User: "Auth/user",
    }),
  },
  watch: {
    statisticsType: function () {
      this.getData();
    },
  },
  methods: {
    getData() {
      if(this.statisticsType) {
        this.getUserStatistics();
      } else {
        this.getDocumentStatistics();
      }
    },
    async getUserStatistics() {
      await axios.get(`/users/statistics?filter[age]=${this.ageSlider}&filter[gender]=${this.gender}`).then(response => {
        this.items = response.data.data;
      })
    },
    async getDocumentStatistics() {
      await axios.get(`/documents/statistics?filter[status]=${this.status}`).then(response => {
        this.items = response.data.data;
      })
    },
    getExport() {
      if(this.statisticsType) {
        this.getUserExport()
      } else {
        this.getDocumentExport();
      }
    },
    getDocumentExport() {
      axios.get(`/documents/statistics/export?filter[status]=${this.status}`, {
        responseType: 'blob'
      }).then(response => {
        const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'statistika.xlsx')
        document.body.appendChild(link)
        link.click()
      });
    },
    getUserExport() {
      axios.get(`/users/statistics/export?filter[age]=${this.ageSlider}&filter[gender]=${this.gender}`, {
        responseType: 'blob'
      }).then(response => {
        const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'statistika.xlsx')
        document.body.appendChild(link)
        link.click()
      });
    }
  },
}
</script>

<style scoped>

</style>
